<template>
  <Page>
    <Card
      v-if="$can('read', 'orders.analytics')"
      content-class="c-table"
    >
      <div class="columns is-mobile is-multiline m-0 pt-2">
        <div class="column is-2-tablet is-6-mobile">
          <b-select-validation
            v-model="warehouse_id"
            :disabled="stats_loading"
            size="is-small"
            hide-details
            required
            :label="$tc('warehouse', 1)"
            @input="getStats"
          >
            <template>
              <option
                v-for="opt in warehouses"
                :key="opt.id"
                :value="opt.id"
              >
                {{
                  `${opt.country.country} - ${opt.currency.code}`
                }}
              </option>
            </template>
          </b-select-validation>
        </div>
        <div class="column is-2-tablet is-6-mobile">
          <b-select-validation
            v-model="salesman_id"
            :disabled="stats_loading"
            size="is-small"
            hide-details
            :label="$tc('salesman', 1)"
            @input="getStats"
          >
            <template>
              <option
                v-for="opt in salesmen"
                :key="opt.id"
                :value="opt.id"
              >
                {{
                  `${opt.fname}  ${opt.lname}`
                }}
              </option>
            </template>
          </b-select-validation>
        </div>
        <div class="column is-2-tablet is-12-mobile">
          <b-select-validation
            v-model="brand_id"
            :disabled="stats_loading"
            size="is-small"
            hide-details
            :label="$tc('brand', 1)"
            @input="getStats"
          >
            <template>
              <option
                v-for="opt in brands"
                :key="opt.id"
                :value="opt.id"
              >
                {{
                  opt.label
                }}
              </option>
            </template>
          </b-select-validation>
        </div>
        <div
          class="column is-6-tablet is-12-mobile is-flex is-justify-content-flex-end"
        >
          <DatesPicker @change="changeDates" />
        </div>
      </div>
      <hr class="my-0">
      <b-table
        ref="table"
        :data="stats"
        :loading="stats_loading"
        detailed
        hoverable
        custom-detail-row
        detail-key="tld"
        show-detail-icon
        :opened-detailed="stats.reduce((arr, s) => [...arr, s.tld], [])"
        :row-class="
          (row, index) =>
            row.site == 'total' ? `bg-cream summary` : `bg-cream`
        "
        :has-detailed-visible="(row, index) => row.items && row.items.length"
      >
        <template v-for="head in stats_headers">
          <b-table-column
            :key="head.value"
            v-slot="props"
            :field="head.value"
            :visible="head.visible"
            :label="head.text"
            :width="head.width"
            :sortable="false"
            header-class="sticky-header"
          >
            <template>
              {{ props.row[head.value] }}
            </template>
          </b-table-column>
        </template>

        <template
          slot="detail"
          slot-scope="props"
        >
          <tr
            v-for="item in props.row.items"
            :key="item.name"
          >
            <td />
            <template v-for="head in stats_headers">
              <td
                v-if="head.visible"
                :key="`item-${head.value}`"
                :class="head.value == 'tld' ? 'pl-5' : ''"
              >
                {{ item[head.value] }}
              </td>
            </template>
          </tr>
        </template>
        <EmptyBlock
          slot="empty"
          icon="view-day"
        />
      </b-table>
    </Card>
  </Page>
</template>

<script>
import DatesPicker from "@/components/forms/elements/DatesPicker";

export default {
  components: {
    DatesPicker
  },
  data() {
    return {
      total: 0,
      rowsPerPage: [50, 100, 200],
      noDataText: this.$i18n.t("no_result_found"),

      // dates data
      dates: [],
      openDateRange: false,
      pagination: {
        itemsPerPage: 50
      },

      // if true send ajax to get stats by dates
      date_filter: {
        from: null,
        to: null,
        warehouse_id: null
      },

      //stats data
      stats: [],
      summaries: [],
      stats_loading: true,
      // customHeaders: [],
      stats_headers: [
        {
          text: "",
          value: "tld",
          visible: true,
          width: 100
        },
        {
          text: this.$i18n.tc("order", 2),
          value: "num_orders",
          visible: true
        },
        {
          text: this.$i18n.t("gross_revenue"),
          value: "gross_revenue",
          visible: true
        },
        {
          text: this.$i18n.t("discount"),
          value: "discount",
          visible: true
        },
        {
          text: this.$i18n.t("net_revenue"),
          value: "net_revenue",
          visible: true
        },
        {
          text: this.$i18n.t("cashback"),
          value: "cashback",
          visible: true
        },
        {
          text: this.$i18n.t("purchase_price"),
          value: "purchase_price",
          visible: false
        },
        {
          text: this.$i18n.t("gross_margin"),
          value: "gross_margin",
          visible: false
        },
        {
          text: this.$i18n.t("gross_margin_ratio"),
          value: "gross_margin_ratio",
          visible: false
        },
        {
          text: this.$i18n.t("shipping"),
          value: "shipping_amount",
          visible: true
        },
        {
          text: this.$i18n.t("avg_cart"),
          value: "avg_cart",
          visible: true
        }
      ],
      stats_data: null,

      salesman_id: null,
      salesmen: [],

      brand_id: null,
      brands: [],

      warehouse_id: null,
      warehouses: []
    };
  },
  watch: {
    // salesman_id() {
    //   this.getStats();
    // },
    // brand_id() {
    //   this.getStats();
    // }
  },
  created() {
    this.updateTitle(this.$i18n.t("overview"));
  },
  methods: {
    changeDates(dates) {
      this.date_filter.from = dates.date.from;
      this.date_filter.to = dates.date.to;
      this.getStats();
    },
    getStats() {
      this.stats_loading = true;
      let api_params = [];

      if (this.warehouse_id)
        api_params.push(`warehouse_id=${this.warehouse_id}`);
      if (this.date_filter.from && this.date_filter.to) {
        api_params.push(`date_from=${this.date_filter.from}`);
        api_params.push(`date_to=${this.date_filter.to}`);
      }
      if (this.brand_id) {
        api_params.push(`brand_id=${this.brand_id}`);
      }
      if (this.salesman_id) {
        api_params.push(`salesman_id=${this.salesman_id}`);
      }

      if (api_params.length) {
        api_params = `?${api_params.join("&")}`;
      } else {
        api_params = "";
      }

      this.$axios
        .get("analytics/overview" + api_params)
        .then(res => {
          let sites = res.data.sites;
          let summary = res.data.summary;
          this.warehouses = res.data.filters.warehouses;
          if (!this.warehouse_id)
            this.warehouse_id = this.warehouses[0] && this.warehouses[0].id;

          if (this.brands.length == 0) this.brands = res.data.filters.brands;
          if (this.salesmen.length == 0)
            this.salesmen = res.data.filters.salesmen.reduce(
              (arr, s) => [...arr, { ...s, name: `${s.fname} ${s.lname}` }],
              []
            );
          if (this.salesmen.length == 1) this.salesman_id = this.salesmen[0].id;
          let stats = [];
          for (let i = 0; i < sites.length; i++) {
            let site = sites[i];
            let siteStats = {
              tld: site.label,
              num_orders: site.summary.num_orders,
              gross_revenue: site.summary.gross_revenue.formatted,
              discount: site.summary.discount.formatted,
              net_revenue: site.summary.net_revenue.formatted,
              cashback: site.summary.cashback.formatted,
              purchase_price: site.summary.purchase_price
                ? site.summary.purchase_price.formatted
                : "-",
              gross_margin: site.summary.gross_margin
                ? site.summary.gross_margin.formatted
                : "-",
              gross_margin_ratio: site.summary.gross_margin_ratio,
              shipping_amount: site.summary.shipping_amount.formatted,
              avg_cart: site.summary.avg_cart.formatted,
              items: []
            };
            for (let j = 0; j < site.tlds.length; j++) {
              let tld = site.tlds[j];
              siteStats.items.push({
                site: site.label,
                tld: tld.label,
                num_orders: tld.stats.num_orders,
                gross_revenue: tld.stats.gross_revenue.formatted,
                discount: tld.stats.discount.formatted,
                net_revenue: tld.stats.net_revenue.formatted,
                cashback: tld.stats.cashback.formatted,
                purchase_price: tld.stats.purchase_price
                  ? tld.stats.purchase_price.formatted
                  : "-",
                gross_margin: tld.stats.gross_margin
                  ? tld.stats.gross_margin.formatted
                  : "-",
                gross_margin_ratio: tld.stats.gross_margin_ratio,
                shipping_amount: tld.stats.shipping_amount.formatted,
                avg_cart: tld.stats.avg_cart.formatted
              });
            }
            stats.push(siteStats);
          }
          if (stats.length != 0) {
            stats.push({
              site: "total",
              tld: this.$t("total"),
              num_orders: summary.num_orders,
              gross_revenue: summary.gross_revenue.formatted,
              discount: summary.discount.formatted,
              net_revenue: summary.net_revenue.formatted,
              cashback: summary.cashback.formatted,
              purchase_price: summary.purchase_price
                ? summary.purchase_price.formatted
                : "-",
              gross_margin: summary.gross_margin
                ? summary.gross_margin.formatted
                : "-",
              gross_margin_ratio: summary.gross_margin_ratio,
              shipping_amount: summary.shipping_amount.formatted,
              avg_cart: summary.avg_cart.formatted
            });
            this.setHeader(stats);
          }

          this.stats = JSON.parse(JSON.stringify(stats));
        })
        .catch(e => this.clientError(e))
        .finally(() => (this.stats_loading = false));
    },
    setHeader(data) {
      let purchase_price_index = this.stats_headers.findIndex(
        h => h.value == "purchase_price"
      );
      let gross_margin_index = this.stats_headers.findIndex(
        h => h.value == "gross_margin"
      );
      let gross_margin_ratio_index = this.stats_headers.findIndex(
        h => h.value == "gross_margin_ratio"
      );
      if (data[0].purchase_price != "-") {
        this.stats_headers[purchase_price_index].visible = true;
      } else {
        this.stats_headers[purchase_price_index].visible = false;
      }
      if (data[0].gross_margin != "-") {
        this.stats_headers[gross_margin_index].visible = true;
      } else {
        this.stats_headers[gross_margin_index].visible = false;
      }
      if (data[0].gross_margin_ratio != "-") {
        this.stats_headers[gross_margin_ratio_index].visible = true;
      } else {
        this.stats_headers[gross_margin_ratio_index].visible = false;
      }
    }
  }
};
</script>
